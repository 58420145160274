@import "../../assets/styles/variables.scss";

header {
  padding: 20px 80px;

  img {
    width: 80px;
  }

  .dropbtn {
    font-size: 20px;
    margin-left: 25px;
  }

  .dropdown {
    position: relative;
    display: inline-block;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    left: -250px;
    background-color: #f1f1f1;
    max-width: 350px;
    width: auto;
    padding: 15px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  .dropdown-content a {
    display: block;
    margin-bottom: 15px;
    font-weight: 100;
    line-height: 25px;
  }

  .dropdown-content a:hover {
    background-color: #ddd;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  .dropdown:hover .dropbtn {
    background-color: $light-gray;
  }
}

@media screen and (max-width: 768px) {
  header {
    padding: 20px;

    img {
      width: 80px;
    }

    .dropbtn {
      display: none;
    }
  }
}
