@import './global.scss';
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
    scroll-behavior: smooth;
    font-family: Regular;
}

a {
    text-decoration: none;
    color: black;
}
