.P-details {
  min-height: 100vh;
  .P-details-content {
    padding: 20px 80px;

    h1 {
      font-size: 30px;
    }

    h2 {
      font-weight: 100;
      font-size: 24px;
      line-height: 40px;
    }

    .P-details-images {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 80px;
      img {
        width: 45%;
        height: 400px;
        margin-bottom: 30px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .P-details {
    .P-details-content {
      padding: 20px;

      h1 {
        font-size: 22px;
      }

      h2 {
        font-size: 18px;
        line-height: 25px;
      }

      .P-details-images {
        display: flex;
        justify-content: unset;
        flex-wrap: unset;
        flex-direction: column;
        margin-top: 50px;
        img {
          width: 100%;
          height: 400px;
          object-fit: cover;
          margin-bottom: 20px;
        }
      }
    }
  }
}
