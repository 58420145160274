.P-home-banner {
  width: 100%;
  height: 70vh;
  background-image: url(../../../assets/images/banner.webp);
  background-size: cover;
  background-position: center;
  padding: 0 80px;

  h1,
  h2 {
    color: white;
    margin-bottom: 25px;
  }

  h2 {
    font-weight: 100;
  }

  a {
    width: 300px;
  }
}

@media screen and (max-width: 768px) {
  .P-home-banner {
    padding: 0 20px;

    h1,
    h2 {
      margin-bottom: 15px;
    }

    h1 {
      font-size: 25px;
    }

    h2 {
      font-size: 18px;
    }

    a {
      width: 50%;
      font-size: 16px !important;
      margin: 0 auto;
    }
  }
}
