@import "../../assets/styles/variables.scss";

footer {
  background: $app-color;
  padding: 20px 80px;
  margin-top: 100px;
  img {
    width: 80px;
  }

  .P-links {
    a {
      color: white;
      margin-bottom: 10px;
    }
  }

  .P-icons {
    img {
      width: 35px;
      height: 35px;
    }
  }
}

@media screen and (max-width:768px) {
  footer {
    padding: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 80px;
      margin-bottom: 20px;
    }
  
    .P-links {
      margin-bottom: 20px;

      a {
        font-size: 15px;
        white-space: nowrap;
      }
    }
  
    .P-icons {
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
  
}
