@import "../../../assets/styles/variables.scss";

.P-home-choose {
  h2 {
    font-size: 50px;
    color: $app-color;
    text-align: center;
  }

  div {
    text-align: center;
    padding: 0 80px;

    h3 {
      font-weight: 100;
      line-height: 25px;
    }
  }
}

@media screen and (max-width: 768px) {
  .P-home-choose {
    h2 {
      font-size: 25px;
    }

    div {
      padding: 0 20px;

      h3 {
        font-size: 18px;
      }
    }
  }
}
