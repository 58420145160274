.P-success-message {
  width: 500px;
  height: 300px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  background: #ffffff;
  padding: 0 40px;

  h2,
  h3 {
    font-family: Regular;
    font-weight: 100;
    letter-spacing: 1.15px;
    text-align: center;
  }
  h3 {
    margin-top: 20px;
  }
}

@media (max-width: 768px) {
  .P-success-message {
    width: 70%;
    height: 200px;
    padding: 0 10px;

    h2 {
      font-size: 16px;
    }

    h3 {
      font-size: 14px;
    }

    h2,
    h3 {
      margin-bottom: 10px;
    }
  }
}
