.P-modal {
    width            : 100%;
    height           : 100%;
    position         : fixed;
    top              : 0;
    left             : 0;
    display          : flex;
    background-color : rgba(189, 185, 185, 0.7);
    z-index          : 10000;
  }
    
  .P-modal-content { cursor: default;}