@import "../../assets/styles/variables.scss";

.P-contact {
  width: 100%;

  h2 {
    font-size: 50px;
    color: $app-color;
    text-align: center;
  }

  .P-contact-banner {
    width: 100%;
    height: 70vh;
    background-image: url("../../assets/images/contact.webp");
    background-size: cover;
    background-position: center;
    padding: 0 80px;

    input,
    button,
    textarea {
      width: 400px;
      margin-bottom: 20px;
      height: 45px;
      border-radius: 15px;
      outline: 0;
      border: 0;
      padding: 15px;
    }

    textarea {
      height: 150px;
      resize: none;
    }
  }
}

@media screen and (max-width: 768px) {
  .P-contact {
    h2 {
      font-size: 25px;
    }

    .P-contact-banner {
      padding: 0 20px;

      input,
      button,
      textarea {
        width: 100%;
        margin-bottom: 15px;
        padding: 10px;
      }

      textarea {
        height: 100px;
      }
    }
  }
}
