@import "../../../assets/styles/variables.scss";

.P-home-about {
  h2 {
    font-size: 50px;
    color: $app-color;
    text-align: center;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: left;

    h3 {
      margin-bottom: 10px;
      font-weight: 100;
    }
  }
}

@media screen and (max-width: 768px) {
  .P-home-about {
    h2 {
      font-size: 25px;
    }

    div {
      text-align: center;

      h3 {
        font-size: 18px;
      }
    }
  }
}
