@import "../../../assets/styles/variables.scss";

.P-home-services {
  h2 {
    font-size: 50px;
    color: $app-color;
    text-align: center;
  }

  .P-services-list {
    width: 100%;
    .P-service-item {
      width: calc(100% / 3);
      height: 400px;
      position: relative;
      background-position: center;
      background-size: cover;

      &:hover {
        .P-sub-item {
          display: block;
          z-index: 10;
          color: white;
          font-size: 18px;
          text-align: center;
          margin-bottom: 15px;
        }
      }
      .P-sub-item {
        display: none;
      }

      .P-layer {
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: $app-color;
        opacity: 0;
        left: 0;
        top: 0;
        transition: 0.5s;
      }

      &:hover {
        span {
          opacity: 1;
        }

        .P-layer {
          opacity: 0.5;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .P-home-services {
    h2 {
      font-size: 25px;
    }

    .P-services-list {
      flex-direction: column;
      .P-service-item {
        width: 100%;
        height: 400px;

        .P-sub-item {
          display: block;
          z-index: 10;
          color: white;
          font-size: 18px;
          text-align: center;
          margin-bottom: 15px;
        }

        .P-sub-item {
          display: block;
        }

        .P-layer {
          opacity: 0.5;
        }
      }
    }
  }
}
